<template>
  <BaseCard class="flex flex-col gap-y-4 p-4">
    <BaseHeading
      as="h2"
      class="text-muted-700 dark:text-muted-400"
    >
      Meld je aan
    </BaseHeading>

    <BaseButton to="/signup" class="primary w-full">
      <Icon name="heroicons:user-group" class="mr-2" />
      Inschrijven als vrijwilliger
    </BaseButton>

    <BaseButton to="/organizations/signup" class="w-full">
      <Icon name="heroicons:building-office" class="mr-2" />
      Inschrijven als organisatie
    </BaseButton>
  </basecard>
</template>
