<script setup lang="ts">
const { data: newsPages, status } = await fetchNews()

const { t } = useI18n({
  useScope: 'local',
})
</script>

<template>
  <ContentFixedSidebarCard :title="t('title')">
    <div v-if="status === 'pending'">
      <BasePlaceload />
    </div>
    <div v-else>
      <ul class="list-disc pl-4">
        <li v-for="newsPage in newsPages" :key="newsPage.id">
          <NuxtLink :to="`/${newsPage.slug}`" class="text-primary-500 text-sm">
            {{ newsPage.title }}
          </NuxtLink>
        </li>
      </ul>
    </div>
  </ContentFixedSidebarCard>
</template>

<i18n lang="json">
  {
    "en": {
      "title": "Recent news"
    },
    "nl": {
      "title": "Laatste nieuws"
    }
  }
</i18n>
