<script setup lang="ts">
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <ContentFixedRecentVacancies />

    <ContentFixedSignupLinksCard />

    <ContentFixedRecentNews />
  </div>
</template>
