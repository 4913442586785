<script setup lang="ts">
// const { layout } = defineProps<{ layout: PageLayoutType }>()

const contentBlockSpacing = inject('contentBlockSpacing', 'space-y-6')
</script>

<template>
  
  <Container class="grid grid-cols-12 gap-x-6" :apply-padding="false">
    <div class="col-span-12">
      <slot name="title" />
    </div>

    <div
      class="col-span-12 gap-x-10 pb-10 sm:col-span-7 md:col-span-9 md:grid-cols-12"
      :class="contentBlockSpacing"
    >
      <slot />
    </div>

    <div
      class="border-l-muted-200 col-span-12 border-l pl-4 sm:col-span-5 md:col-span-3"
    >
      <slot name="side_column">
        <ContentFixedSidebar />
      </slot>
    </div>
  </Container>
</template>
